import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import styles from "./styles.module.scss";

const Footer = () => {
  const basename = process.env.REACT_APP_BASENAME
    ? process.env.REACT_APP_BASENAME.trimEnd()
    : "";

  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <Container>
        <Row className="d-none d-md-flex">
          <Col>
            <img
              src={basename + "/images/footer-logo.svg"}
              alt="Twogether Creative Limited"
            />
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            &copy; {currentYear} Twogether Creative Limited
          </Col>
        </Row>
        <Row className="d-block d-md-none">
          <Col className="mb-4 text-center">
            &copy; {currentYear} Twogether Creative Limited
          </Col>
        </Row>
        <Row className="d-block d-md-none">
          <Col className="text-center">
            <img
              src={basename + "/images/footer-logo.svg"}
              alt="Twogether Creative Limited"
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
