import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Row,
  Col,
} from "react-bootstrap";
import { useMediaQuery } from "usehooks-ts";
import Logo from "../Logo";

import styles from "./styles.module.scss";

const Index = () => {
  const matches = useMediaQuery("(max-width: 992px)");
  const [useMobileMenu, setUseMobileMenu] = useState(false);

  useEffect(() => {
    if (matches === useMobileMenu) return;

    setUseMobileMenu(matches);
  }, [matches, useMobileMenu]);

  return (
    <>
      <Navbar
        key={"main-nav"}
        expand={!useMobileMenu}
        fixed="top"
        className={
          styles.navbar +
          " " +
          (!useMobileMenu ? "d-none d-lg-block" : "d-block d-lg-none")
        }
      >
        <Container fluid>
          <Navbar.Brand as={NavLink} to="/">
            <Logo />
          </Navbar.Brand>
          <Navbar.Brand as={NavLink} to="/" className={styles.navbarBrand}>
            Developer
            <br />
            &lt;\&gt; Hub
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`main-menu-nav`} />
          <Navbar.Offcanvas
            id={`main-menu-nav`}
            aria-labelledby={`offCanvasNavbarLabel-expand`}
            placement="end"
            className={styles.offCanvas}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offCanvasNavbarLabel-expand`}>
                <Row>
                  <Col>
                    <Logo />
                  </Col>
                  <Col className={styles.navbarBrand}>
                    Developer
                    <br />
                    &lt;\&gt; Hub
                  </Col>
                </Row>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                className={
                  styles.navLinks + " justify-content-end flex-grow-1 pe-3"
                }
              >
                <NavDropdown
                  id={`nav-getting-setup-expand`}
                  title="Getting Setup"
                >
                  <NavDropdown.Item as={NavLink} to="/setup/ssh-keys">
                    SSH Keys
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/setup/projects">
                    Projects
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/setup/deployments">
                    CI / CD Deployments
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/setup/environments">
                    Environments
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/setup/ai">
                    AI
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  id={`nav-development-standards-expand`}
                  title="Standards"
                >
                  <NavDropdown.Item as={NavLink} to="/standards/coding">
                    Coding Standards
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/standards/projects">
                    Project Standards
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to="/standards/local-development"
                  >
                    Local Development Standards
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/standards/website">
                    Website Standards
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to="/standards/naming-conventions"
                  >
                    Naming Convention Standards
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/standards/security">
                    Security Standards
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  id={`nav-documentation-expand`}
                  title="Documentation"
                >
                  <NavDropdown.Item as={NavLink} to="/documentation/readme">
                    README's
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to="/documentation/gitlab-workflow"
                  >
                    GitLab Workflow
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to="/documentation/merge-requests"
                  >
                    Merge Requests
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to="/documentation/web-proxy-service"
                  >
                    Web Proxy Service
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/documentation/tracking">
                    Analytics / Tracking
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={NavLink} to="/documents">
                  Documents
                </Nav.Link>
                <Nav.Link as={NavLink} to="/points-of-contact">
                  Points of Contact
                </Nav.Link>
                <Nav.Link as={NavLink} to="/resources">
                  Resources
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Index;
